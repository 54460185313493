import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Avatar,
  Badge,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import NotificationsIcon from "@mui/icons-material/Notifications";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";

import IconButtonPopover from "../../../components/IconButtonPopover";

import { ENotificationJobComment, Role } from "../../../constant/common";
import { QAndAFromCandidateAndHrConfigApi } from "../../../services/Admin/qAndAFromCandidateAndHr";

const BASE_PATH = process.env.REACT_APP_IMAGE_URL;

interface INotificationProps {}

const StyledBadge = styled(Badge)(({ theme }) => ({
  marginTop: "10px",

  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""'
    }
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0
    }
  }
}));

export const NotificationSystem: React.FC<INotificationProps> = ({}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const role = Cookies.get("role") || "";

  const jobCommentNotificationQuery = useQuery(
    ["jobCommentNotificationQuery"],
    async () => {
      const res = await QAndAFromCandidateAndHrConfigApi.v1GetNotification();
      return res.data;
    },
    {
      enabled: role !== Role.U_INPUT
    }
  );

  const handleGoToDetailComment = async (item: any) => {
    if (item?.type === ENotificationJobComment.CREATED_COMMENT) {
      navigate(
        `/admin/comment-questions-and-answers-job/${item?.metadata?.data?.job?.uuid}?questionId=${item?.metadata?.data?.uuid}`,
        { replace: true }
      );
    } else if (item?.type === ENotificationJobComment.CREATED_REPLY_COMMENT) {
      navigate(
        `/admin/comment-questions-and-answers-job/reply/${item?.metadata?.data?.mainComment?.uuid}?answerId=${item?.metadata?.data?.uuid}&jobId=${item?.metadata?.data?.job?.uuid}`,
        { replace: true }
      );
    }

    try {
      await QAndAFromCandidateAndHrConfigApi.v1PutClickNotification(item?.uuid);
      jobCommentNotificationQuery?.refetch();
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <Badge
      badgeContent={
        jobCommentNotificationQuery?.data?.data?.filter(
          (item: any) => !item?.isClick
        )?.length || 0
      }
      color="error"
      sx={{
        position: "fixed",
        top: 13,
        right: "7%",
        zIndex: 1300,
        cursor: "pointer",

        "@media (max-width: 1440px)": {
          right: "9%"
        },

        "@media (max-width: 1220px)": {
          right: "10%"
        },

        "& .MuiBadge-badge": {
          top: 10,
          right: 10
        }
      }}
    >
      <IconButtonPopover
        icon={<NotificationsIcon color="primary" />}
        content={
          <List
            sx={{
              width: "100%",
              maxWidth: 460,
              bgcolor: "background.paper",
              cursor: "pointer"
            }}
          >
            {jobCommentNotificationQuery?.data?.data?.length > 0 ? (
              jobCommentNotificationQuery?.data?.data?.map(
                (item: any, index: number) => (
                  <div key={`notification-${index}`}>
                    <ListItem
                      alignItems="flex-start"
                      onClick={() => handleGoToDetailComment(item)}
                      sx={{
                        backgroundColor: !item?.isClick
                          ? "#00a5ffb3"
                          : theme.palette.mode === "dark"
                          ? "#2E2E2E"
                          : "#fff"
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          alt="avatar"
                          src={`${BASE_PATH}${
                            item?.metadata?.data?.user?.avatar || ""
                          }`}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={item?.metadata?.data?.user?.full_name}
                        secondary={
                          <React.Fragment>
                            {item?.metadata?.data?.comment}
                          </React.Fragment>
                        }
                      />
                      {!item?.isClick && (
                        <StyledBadge
                          overlap="circular"
                          variant="dot"
                        ></StyledBadge>
                      )}
                    </ListItem>
                    {index <
                      jobCommentNotificationQuery?.data?.data.length - 1 && (
                      <Divider variant="inset" component="li" />
                    )}
                  </div>
                )
              )
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  padding: "16px 12px"
                }}
              >
                <WarningRoundedIcon fontSize="small" sx={{ color: "red" }} />
                <span>Chưa có thông báo mới</span>
              </div>
            )}
          </List>
        }
        sxPopover={{
          maxHeight: 400
        }}
      />
    </Badge>
  );
};
